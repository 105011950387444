/*
 * @Date: 2021-03-24 15:14:33
 * @LastEditors: wfj
 * @LastEditTime: 2023-07
 * @FilePath: /dc-pay-front/src/main.js
 */
import '@/utils/adaptRem'
import '@/styles/index.less'
import { useLanguageList } from '@/hooks/useLanguageList'

async function initHook () {
  const { initLanguageList } = useLanguageList()
  await initLanguageList()

  const { default: Initializer } = await import('@/core/initializer')
  const init = new Initializer()

  init.listenerGlobalError()

  init.initDevPackage()

  if (!init.allowEntryHtml()) return

  init.injectGlobal()

  // 调用接口设置默认语言
  await init.setDefaultLanguage()

  init.initVue()
}

initHook()
