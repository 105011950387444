import { Toast } from 'vant'
import { LanguageData } from '@/utils/globalData'
import {
  getVersionDetailInfo,
  getXHRBaseUrl,
  getVersionCompanyId
} from '@/utils/versionHelper'
import { initTrack } from '@/utils/trackHelper'
import Vue from 'vue'
import AppMaintenance from '@/components/AppMaintenance.vue'
import axios from 'axios'
import { getUUID } from '@/utils'

let languageList = []

export function useLanguageList () {
  /**
   * 获取后台配置的语言列表
   */
  async function fetchLanguageList (logical_region_id) {
    const ins = axios.create({
      baseURL: getXHRBaseUrl()
    })

    const { data } = await ins.post(
      '/version/List',
      {},
      {
        headers: {
          'operate-entity-id': getVersionCompanyId(),
          'logical-region-id': logical_region_id,
          trace_id: getUUID()
        }
      }
    )

    if (data.code === 0 && data.data.langs.length) {
      return data.data.langs
    }

    throw new Error('Can not fetch language list')
  }

  /**
   * 合并后端返回的语言列表，value 修改为来自后台配置
   */
  function mapLanguageList (langList) {
    const result = []
    langList.forEach((item) => {
      const { lang, lang_name } = item
      const { languageMap } = LanguageData
      if (languageMap[lang]) {
        result.push(Object.assign({}, languageMap[lang], { value: lang_name }))
      }
    })

    return result
  }

  async function initLanguageList () {
    let { region: logical_region_id } = getVersionDetailInfo()
    logical_region_id = logical_region_id ? logical_region_id.join('_') : ''

    const t = Toast.loading({ duration: 0 })

    try {
      const langList = await fetchLanguageList(logical_region_id)
      languageList = mapLanguageList(langList)
    } catch (e) {
      onLoadLanguageListFailed()
      throw e
    } finally {
      t.clear()
    }
  }

  async function onLoadLanguageListFailed () {
    initTrack().track_event('app_start_err', {
      reason: 'Can not get language list'
    })
    // 显示 应用维护中
    const app = new Vue(AppMaintenance)
    app.$mount('#app')
  }

  return {
    languageList,
    initLanguageList,
    onLoadLanguageListFailed
  }
}
