import { versions, COMPANY_MAP } from './globalData'

/**
 * 获取版本信息
 * @returns { gameCode: 'wsy', region: 'tw', versionName: 'wsy-tw' }
 */
export const getVersionByParseUrl = () => {
  let pathname = window.location.pathname
  if (!pathname || pathname === '/') {
    pathname = '/wsy-tw/'
  }
  const versionName = pathname.replace(/\//g, '')
  const pathnameArr = versionName.split('-')
  return { gameCode: pathnameArr[0], region: pathnameArr[1], versionName }
}

/**
 * 获取详细版本信息
 * @returns 见 versions item
 */
export const getVersionDetailInfo = () => {
  const version = getVersionByParseUrl()
  return versions[version.gameCode][version.region]
}

// 获取版本信息中指定key的值
export const getVersionInfoByKey = (key) => getVersionDetailInfo()?.[key]

export const getXHRBaseUrl = () => {
  const { serverRegion } = getVersionDetailInfo()
  return serverRegion.fetchBaseUrl
}

// 返回当前版本的公司主体
export const getVersionCompanyId = () =>
  getVersionInfoByKey('companyId') || COMPANY_MAP.dc
