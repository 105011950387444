/*
 * @Author: wfj
 * @Date: 2021-04-07 09:23:10
 * @LastEditors: wfj
 * @LastEditTime: 2022-09
 * @FilePath: \vizier-cli-plugins\packages\vue-cli-plugin-dc-h5-rotate\generator\portraitTpl\src\utils\adaptRem.js
 */
function adapt (designWidth, rem2px) {
  const d = window.document.createElement('div')
  d.style.width = '1rem'
  d.style.display = 'none'
  const head = window.document.getElementsByTagName('head')[0]
  head.appendChild(d)
  const defaultFontSize = parseFloat(
    window.getComputedStyle(d, null).getPropertyValue('width')
  )
  // d.remove();
  // document.documentElement.style.fontSize = window.innerWidth / designWidth * rem2px / defaultFontSize * 100 + '%';
  const st = document.createElement('style')
  const portrait =
    '@media screen and (min-width: ' +
    window.innerWidth +
    'px) {html{font-size:' +
    (window.innerWidth / (designWidth / rem2px) / defaultFontSize) * 100 +
    '%;}}'
  const landscape =
    '@media screen and (min-width: ' +
    window.innerHeight +
    'px) {html{font-size:' +
    (window.innerHeight / (designWidth / rem2px) / defaultFontSize) * 100 +
    '%;}}'
  st.innerHTML = portrait + landscape
  head.appendChild(st)
  return defaultFontSize
}

(function (doc, win) {
  const designSize = 750
  const docEl = doc.documentElement
  const resizeEvt =
    'orientationchange' in window ? 'orientationchange' : 'resize'
  const defaultFontSize = adapt(designSize, 100)
  const recalc = function () {
    const clientWidth =
      docEl.clientWidth > designSize ? designSize : docEl.clientWidth
    if (!clientWidth) return
    // docEl.style.fontSize = clientWidth / 10 + 'px';
    if (clientWidth <= designSize) {
      docEl.style.fontSize =
        (((clientWidth / designSize) * 100) / defaultFontSize) * 100 + '%'
    } else {
      docEl.style.fontSize = '468.75%'
    }
  }
  if (!doc.addEventListener) return
  win.addEventListener(resizeEvt, recalc, false)
  doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)
